// *********************************************************
// IMPORTANT: THESE CONSTANTS NEED TO BE IN SYNC WITH
//  web.models.company types
// *********************************************************

export const COMPANY_TYPE_MAP = {
  100: 'vendor',
  200: 'client',
};

export default {
  vendor: 100,
  client: 200,
};

export const CLIENT_COMPANY_PLACEHOLDER = {
  name: 'Client',
  id: 'client-obj',
};
