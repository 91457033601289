import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  fill?: string
  strokeWidth?: number
};

const SortArrow = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color = 'currentColor', fill = 'none', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={fill}
      ref={ref}
      {...otherProps}
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        fill={fill}
        d="M6.75 14.75 12 9.25l5.25 5.5H6.75Z"
      />
    </svg>
  );
});

SortArrow.displayName = 'SortArrow';

export default SortArrow;
