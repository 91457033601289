import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Autopilot = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M21 4L3 13.891l5.807 1.476-.065 3.883 2.072-3.447 5.535 3.848L21 4z"
        stroke={color}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.807 15.367L21 4 10.814 15.803"
        stroke={color}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

Autopilot.displayName = 'Autopilot';

export default Autopilot;
